import React, { useState } from "react";
import WithdrawalTable from "../Tables/Withdrawals";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import { Box, Typography } from "@material-ui/core";
import UseStyles from "./styles";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { TableContainer, TableHead, TextField } from "@material-ui/core";
import { agentWithdrawal } from "../../actions/agent.js";
import OkDialog from "../../components/Dialogs/OkDialog";
import ConfirmDialog from "../../components/Dialogs/DepositConfirmDialog";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import AgentRequestWithdrawalBTC from "./AgentRequestWithdrawalBTC";
import AgentRequestWithdrawalETH from "./AgentRequestWithdrawalETH";
import AgentRequestWithdrawalUSDTTRON from "./AgentRequestWithdrawalUSDTTRON";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useHistory } from "react-router-dom";
import { LOGIN } from "../../constants/actionTypes";

const initialState = {
	txid_hash: "",
};

const theme = createTheme({
	palette: {
		primary: {
			main: "#FFFFFF",
			textTransform: "none",
		},
		secondary: {
			main: "#0560fb",
			textTransform: "none",
		},
	},
	root: {
		textTransform: "none",
	},
});

const CustomTab = withStyles({
	root: {
		textTransform: "none",
		paddingLeft: "15px",
		paddingRight: "15px",
		fontSize: "18px",
		fontWeight: "800",
		color: "#0560fb",
	},
})(Tab);

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	const classes = UseStyles();

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && <Box sx={{ p: 0 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const Withdrawals = () => {
	const history = useHistory();
	const agentId = useSelector((state) => state?.login?.loginData?.agentId);
	const merchant = useSelector((state) => state?.login?.loginData?.merchant);
	const token = useSelector((state) => state?.login?.loginData?.token);
	//const agentId = JSON.parse(localStorage.getItem('profile')).agentId;
	//const merchant = JSON.parse(localStorage.getItem('profile')).merchant;
	const [formData, setFormData] = useState(initialState);
	const [value, setValue] = React.useState(0);
	const [okDialog, setOkDialog] = useState({
		isOpen: false,
		title: "",
		subTitle: "",
	});
	const [confirmDialog, setConfirmDialog] = useState({
		isOpen: false,
		title: "",
		subTitle: "",
		totalFeesMerchant: 0,
		totalAmountSatDep: 0,
		totalDepOnBTC: 0,
		totalFeeInUSD: 0,
		totalAmountUSDDep: 0,
		exchange: 0,
		balance: 0,
	});
	const classes = UseStyles();
	const dispatch = useDispatch();

	let isMobile = false;
	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
		isMobile = true;
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		document.body.style.cursor = "wait";
		dispatch(
			agentWithdrawal(agentId, merchant, formData.txid_hash, token, (res) => {
				if (res.error) {
					if (res.message === "Token Expire") {
						dispatch({ type: LOGIN, res });
						history.push("/");
					}
				}
				if (res.error) {
					document.body.style.cursor = "default";
					setOkDialog({
						isOpen: true,
						title: "Error on withdrawal process.",
						subTitle: res?.message,
						onConfirm: () => {
							setOkDialog({
								...okDialog,
								isOpen: false,
							});
						},
					});
				} else {
					document.body.style.cursor = "default";
					setConfirmDialog({
						isOpen: true,
						title: "Withdrawal Applied",
						subTitle: "Withdrawal Detail",
						totalFeesMerchant: res.data.totalFeesMerchant,
						totalAmountSatDep: res.data.totalAmountSatDep,
						totalDepOnBTC: res.data.totalDepOnBTC,
						totalFeeInUSD: res.data.totalFeeInUSD,
						totalAmountUSDDep: res.data.totalAmountUSDDep,
						exchange: res.data.exchange,
						balance: res.data.balance,
						onConfirm: () => {
							setConfirmDialog({
								...confirmDialog,
								isOpen: false,
							});
						},
					});
					document.getElementById("txid_hash").value = "";
				}
			})
		);
	};

	const handleChange = async (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleChangeTab = (event, newValue) => {
		setValue(newValue);
	};

	const agentSession = useSelector((store) => store.agent);

	let balanceBTC = agentSession?.agentData?.data?.balanceBTC;

	let exch = agentSession?.agentData?.exchange;

	return (
		<>
			{agentId !== "admin1" ? (
				<>
					<ThemeProvider theme={theme}>
						<Box sx={{ width: "100%" }}>
							<Box
								sx={{
									borderBottom: 1,
									borderColor: "divider",
									textAlign: "left",
								}}
							>
								<Tabs
									TabIndicatorProps={{
										sx: { display: "none" },
									}}
									sx={{
										"& .MuiTabs-flexContainer": {
											flexWrap: "wrap",
										},
									}}
									value={value}
									onChange={handleChangeTab}
									aria-label=""
									variant="scrollable"
									orientation={isMobile === true ? "vertical" : "horizontal"}
								>
									<CustomTab label="Withdrawals" {...a11yProps(0)} />
									<CustomTab label="BTC Withdrawal" {...a11yProps(1)} />
									<CustomTab label="LN Withdrawal" {...a11yProps(2)} />
									<CustomTab label="ETH Withdrawal" {...a11yProps(3)} />
									<CustomTab label="TRON Withdrawal" {...a11yProps(4)} />
								</Tabs>
							</Box>

							<TabPanel className={classes.tabpanel} value={value} index={0}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={12}>
										<Card style={{ borderRadius: "15px" }}>
											<CardHeader color="info" style={{ borderRadius: "15px" }}>
												<Typography className={classes.agentName}>{"Withdrawals"}</Typography>
											</CardHeader>
											<CardBody>
												<WithdrawalTable agentId={agentId} merchant={merchant} />
											</CardBody>
										</Card>
									</GridItem>
									<OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
									<ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
								</GridContainer>
							</TabPanel>
							<TabPanel className={classes.tabpanel} value={value} index={1}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={12}>
										<Card className={classes.headerCard}>
											<CardHeader color="info" style={{ borderRadius: "15px" }}>
												<Typography className={classes.agentName}>{"Bitcoin Main Network Withdrawal Request"}</Typography>
											</CardHeader>
											<CardBody>
												<AgentRequestWithdrawalBTC />
											</CardBody>
										</Card>
									</GridItem>
								</GridContainer>
							</TabPanel>
							<TabPanel value={value} index={2}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={12}>
										<Card className={classes.headerCard}>
											<CardHeader color="info" style={{ borderRadius: "15px" }}>
												<Typography className={classes.agentName}>{"Lightning Network Withdrawal Request"}</Typography>
											</CardHeader>
											<CardBody>
												<TableContainer>
													<Table className={classes.table}>
														<TableBody>
															<TableRow>
																<TableCell colSpan={2}>
																	<Typography className={classes.texto}>Address Code</Typography>
																	<TextField
																		placeholder="Paste your address code here."
																		multiline
																		fullWidth
																		minRows={6}
																		maxRows={6}
																		name="txid_hash"
																		id="txid_hash"
																		onChange={handleChange}
																		InputProps={{
																			disableUnderline: true,
																		}}
																		className={classes.textarea}
																	/>
																</TableCell>
															</TableRow>
															<TableRow>
																<TableCell colSpan={2} align="center" className={classes.tablecell}>
																	<Typography
																		sx={{
																			fontWeight: "700",
																		}}
																	>{`Maximun request: $${parseFloat(balanceBTC * exch).toFixed(2)}`}</Typography>
																</TableCell>
															</TableRow>
															<TableRow>
																<TableCell colSpan={2} align="center" className={classes.tablecell}>
																	<Button
																		type="submit"
																		variant="contained"
																		className={classes.submit}
																		onClick={handleSubmit}
																	>
																		{"Send Request"}
																	</Button>
																</TableCell>
															</TableRow>
														</TableBody>
													</Table>
												</TableContainer>
											</CardBody>
										</Card>
									</GridItem>
								</GridContainer>
							</TabPanel>
							<TabPanel className={classes.tabpanel} value={value} index={3}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={12}>
										<Card className={classes.headerCard}>
											<CardHeader color="info" style={{ borderRadius: "15px" }}>
												<Typography className={classes.agentName}>{"Ethereum Network Withdrawal Request"}</Typography>
											</CardHeader>
											<CardBody>
												<AgentRequestWithdrawalETH />
											</CardBody>
										</Card>
									</GridItem>
								</GridContainer>
							</TabPanel>
							<TabPanel className={classes.tabpanel} value={value} index={4}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={12}>
										<Card className={classes.headerCard}>
											<CardHeader color="info" style={{ borderRadius: "15px" }}>
												<Typography className={classes.agentName}>{"TRON Network Withdrawal Request"}</Typography>
											</CardHeader>
											<CardBody>
												<AgentRequestWithdrawalUSDTTRON />
											</CardBody>
										</Card>
									</GridItem>
								</GridContainer>
							</TabPanel>
						</Box>
					</ThemeProvider>
				</>
			) : null}
		</>
	);
};

export default Withdrawals;
